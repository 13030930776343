import * as React from "react";
import * as classNames from "classnames";

import { ApiContext, CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Image, ImageSpec, LocalizedImageSpecOptions } from "../../../media";
import { ImageWidgetOptions, LocalizedFigcaption, LocalizedImageWidgetOptions, LocalizedMarkerSpecOptions, Marker } from "./";
import { Site, WithId } from "@maxxton/cms-api";
import { getClassName, getImageAndSpec, getLocalizedImageWidgetOptions, getMarkerAndAltText, getUrl } from "./imageWidget.utils";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { CMSAware } from "../../../containers/CmsProvider";
import { CurrentLocale } from "../../../app.types";
import { Image as DynamicImages } from "@maxxton/cms-mxts-api";
import { FrontendPageEditState } from "../../../redux/reducers/frontendPageEditReducer";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isEqual } from "lodash";
import withEditModal from "../../../components/frontend-editing/WithEditModal";
import { wrapProps } from "../../../i18n";

interface ImagePropsBase extends PageWidgetBaseProps<ImageWidgetOptions> {
    context: CMSProvidedProperties;
    className: string;
    image: Image | null;
    imageSpec: ImageSpec;
    url: string;
    alternativeText: string;
    mapMarkers?: any[];
    target?: string;
    rel?: string;
    currentLocale: CurrentLocale;
    apiContext: ApiContext;
}

interface ImageStoreProps {
    frontendPageEditState: FrontendPageEditState;
}

interface ImageProps extends CMSProvidedProperties, ImagePropsBase, ImageStoreProps {}

interface ImageState {
    disableWidget: boolean;
    sites: Array<Site & WithId>;
    image: Image | null;
    alternativeText: string;
    mapMarkers?: any[];
    dynamicImages?: DynamicImages[];
    options: ImageWidgetOptions;
    className: string;
    url: string;
    imageSpec: ImageSpec;
}

export class ImageBase extends React.PureComponent<ImageProps, ImageState> {
    constructor(props: ImageProps) {
        super(props);
        this.state = {
            sites: [],
            disableWidget: true,
            imageSpec: props.imageSpec,
            image: props.image,
            alternativeText: props.alternativeText,
            mapMarkers: props.mapMarkers,
            options: props.options,
            className: props.className,
            url: props.url,
        };
    }

    public async componentDidMount() {
        const { context } = this.props;
        if (this.props.mapMarkers?.length || this.state.mapMarkers) {
            this.props.context.cmsApi.siteApi.find({ projection: { sitemap: 0 } }).then((res) => {
                // TODO: instead of obtaining all sites we should only obtain the ones we actually need.
                this.setState(() => ({ sites: res }));
            });
        }
        this.setState({ disableWidget: !isClientLoggedIn() });
        if (this.props.options.getLogoFromDC && context.distributionChannelId) {
            const env = await getMxtsEnv(context, context.currentLocale.code);
            const distributionChannel = await context.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: +context.distributionChannelId }]).catch((error: Error) => {
                context.logger.error(error.message);
                return undefined;
            });
            const dynamicImages = await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: distributionChannel?.imageManagerId }]);
            this.setState({ dynamicImages });
        }
    }

    public componentDidUpdate(prevProps: Readonly<ImageProps>): void {
        if (!isEqual(this.props.options, prevProps.options)) {
            this.updateImageOptions(this.props.options);
        }
    }

    public updateImageOptions = async (options: ImageWidgetOptions) => {
        const { apiContext, context } = this.props;
        const { site, currentLocale } = context;

        const localizedImageWidgetOptions = getLocalizedImageWidgetOptions(options, site, currentLocale);
        const { image, imageSpec } = await getImageAndSpec(apiContext, options, localizedImageWidgetOptions);
        const { altText, markers } = await getMarkerAndAltText(apiContext, options, site, currentLocale, imageSpec);
        const className = getClassName(context, options);
        const url = await getUrl(context, site, currentLocale, localizedImageWidgetOptions);
        this.setState({ imageSpec, image, url, mapMarkers: markers, alternativeText: altText, className });
    };
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { options, imageSpec, url, className } = this.state;

        const {
            currentLocale,
            context: { site, device },
        } = this.props;
        const { sites, alternativeText, mapMarkers, image, dynamicImages } = this.state;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor, getLogoFromDC } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        if (hideWidget === null) {
            return null;
        }

        const { imageUse } = options;
        const localizedImageWidgetOptions: LocalizedImageWidgetOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
        const localizedFigcaption: LocalizedFigcaption | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedFig });
        const defaultLocalizedImageSpecOptions: LocalizedImageSpecOptions = {
            locale: "",
        };
        const localizedImageSpecOptions: LocalizedImageSpecOptions = getLocalizedContent({ site, currentLocale, localizedContent: imageSpec.options.localized }) || defaultLocalizedImageSpecOptions;
        const { alt } = localizedImageSpecOptions;
        let linkUrl: string | undefined;
        let externalUrl: string | undefined;

        if (url) {
            linkUrl = url;
        }
        if (localizedImageWidgetOptions?.useExtUrl) {
            externalUrl = localizedImageWidgetOptions.useExtUrl ? localizedImageWidgetOptions.useExternalUrl : "";
        }

        let imageUrl;
        if (image && !getLogoFromDC) {
            imageUrl = image.renderUrl(imageSpec, localizedImageSpecOptions);
        } else {
            imageUrl = dynamicImages ? dynamicImages[0]?.urls?.original?.replace("t_newyse_original", "t_mcms_original") : "";
        }
        const divStyle = {
            backgroundImage: "url(" + imageUrl + ")",
            backgroundPositionY: options.styleYPlacement
                ? options.styleYPlacement.indexOf("%") > -1
                    ? options.styleYPlacement
                    : options.styleYPlacement + (isNaN(+options.styleYPlacement) ? "" : "%")
                : undefined,
            backgroundPositionX: options.imageUseXPlacement
                ? options.styleXPlacement.indexOf("px") > -1
                    ? options.styleXPlacement
                    : options.styleXPlacement + (isNaN(+options.styleXPlacement) ? "" : "px")
                : "center",
        };

        const mobileDivStyle = {
            backgroundImage: "url(" + imageUrl + ")",
            backgroundPositionY: options.styleYPlacementOnMobile
                ? options.styleYPlacementOnMobile.indexOf("%") > -1
                    ? options.styleYPlacementOnMobile
                    : options.styleYPlacementOnMobile + (isNaN(+options.styleYPlacementOnMobile) ? "" : "%")
                : "",
            backgroundPositionX: options.styleXPlacementOnMobile
                ? options.styleXPlacementOnMobile?.indexOf("%") > -1
                    ? options.styleXPlacementOnMobile
                    : options.styleXPlacementOnMobile + (isNaN(+options.styleXPlacementOnMobile) ? "" : "%")
                : "center",
        };

        const figcaption = <figcaption>{options.Usefigcaption && (localizedFigcaption?.localizedFigcaption || alternativeText || image?.name || "")}</figcaption>;
        const texts = (
            <div className="hero-image__text">
                {options.headerText ? (
                    <div className="hero-image__payoff hero__payoff">{localizedImageWidgetOptions?.contentTitlePayoff || ""}</div>
                ) : (
                    <h1 className="hero-image__payoff hero__payoff">{localizedImageWidgetOptions?.contentTitlePayoff || ""}</h1>
                )}
                {localizedImageWidgetOptions?.contentSubTitlePayoff ? <h2 className="hero-image__payoff-text hero__payoff-text">{localizedImageWidgetOptions.contentSubTitlePayoff}</h2> : null}
            </div>
        );
        const useContentWise = options.imageUse ? texts : options.Usefigcaption ? figcaption : "";
        const inline = (
            <div
                // eslint-disable-next-line max-len
                className={`hero-image__image ${className} ${options.moreHeight && options.heightDesktop ? "default-img-height-" + options.heightDesktop : ""} ${
                    options.moreHeight && options.heightTablet ? "tab-img-height-" + options.heightTablet : ""
                } ${options.moreHeight && options.heightMobile ? "mob-img-height-" + options.heightMobile : ""} ${
                    options.imageUseXPlacement && options.imageUsePlacement ? "horizontal-position" : ""
                } ${options.moreHeight ? "" : ""} ${hideWidget}`}
                style={device?.isMobile ? mobileDivStyle : divStyle}
            >
                {options.Usefigcaption ? figcaption : ""}
                {localizedImageWidgetOptions?.contentTitlePayoff || localizedImageWidgetOptions?.contentSubTitlePayoff ? useContentWise : ""}
            </div>
        );
        const urlArray = image?.url?.split("/") || "";
        const videoUrl = image?.url && (image.url.indexOf("?v=") > -1 ? image.url.split("?v=")[1] : urlArray && urlArray[urlArray.length - 1]);
        const mediaType = image?.mediaType || dynamicImages?.[0]?.mediaType || "";
        const figure = (
            // eslint-disable-next-line max-len
            <figure
                className={classNames(hideWidget, {
                    "media-video": mediaType === "VIDEO",
                    ["image-width-" + options.imageWidth]: !!options.imageWidth,
                    "no-margin-bottom": options.removeFigureMargin,
                })}
            >
                {mediaType &&
                    (mediaType !== "VIDEO" ? (
                        <img
                            loading="lazy"
                            srcSet={`${image?.presets.small.url} 300w, ${image?.presets.medium.url} 768w, ${imageUrl} 1280w`}
                            sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                            className={classNames(className, { ["object-fit-" + options.objectFit]: !!options.objectFit, "w-100": options.fullWidth })}
                            src={imageUrl}
                            height={imageSpec.options.height ? imageSpec.options.height : undefined}
                            width={imageSpec.options.width ? imageSpec.options.width : undefined}
                            alt={alt || image?.name}
                        />
                    ) : (
                        <iframe
                            className={className}
                            height={imageSpec.options.height}
                            width={imageSpec.options.width}
                            id="ytplayer"
                            // eslint-disable-next-line max-len
                            src={`https://www.youtube-nocookie.com/embed/${videoUrl}?enablejsapi=1&modestbranding=1&autoplay=0&loop=0&controls=0&rel=0&autohide=1`}
                        />
                    ))}
                {options.Usefigcaption ? figcaption : ""}
            </figure>
        );
        const showImage = imageUse ? inline : figure;
        const classNameMap = "map-view__island";
        if (!localizedImageWidgetOptions?.useExtUrl && localizedImageWidgetOptions?.pageId && localizedImageWidgetOptions?.siteId) {
            return (
                <SmartLink className={`inline-image ${hideWidget}`} href={linkUrl} target={localizedImageWidgetOptions.openNewTab ? "_blank" : "_self"}>
                    {showImage}
                    {localizedImageWidgetOptions.contentTitlePayoff ? useContentWise : ""}
                </SmartLink>
            );
        } else if (localizedImageWidgetOptions?.useExtUrl) {
            return (
                <SmartLink
                    className={`inline-image ${hideWidget}`}
                    href={externalUrl}
                    target={localizedImageWidgetOptions.openNewTab ? "_blank" : "_self"}
                    // eslint-disable-next-line max-len
                    rel={localizedImageWidgetOptions.noFollowLink ? "nofollow" : undefined}
                >
                    {showImage}
                    {localizedImageWidgetOptions.contentTitlePayoff ? useContentWise : ""}
                </SmartLink>
            );
        } else if (imageUse && !options.useMapMarkers) {
            return showImage;
        } else if (options.useMapMarkers && options.markers && options.markers.length > 0 && image && image.mediaType !== "VIDEO") {
            const useMapMarker = options.useMapMarkers ? classNameMap : "";
            return (
                <div className={`${useMapMarker} ${hideWidget}`}>
                    <figure>
                        <img loading="lazy" className={className} src={imageUrl} alt={alternativeText ? alternativeText : image ? image.name : ""} />
                    </figure>
                    {options.markers &&
                        options.markers.map((marker: Marker) => {
                            const markerImage = mapMarkers ? mapMarkers.find((item) => item.markerId === marker.markerId) : undefined;
                            const localizedMarkerDetails: LocalizedMarkerSpecOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: marker?.localized || [], keys: ["title"] });
                            const localizedMarkerCoordinates: LocalizedMarkerSpecOptions | null = getLocalizedContent({
                                site,
                                currentLocale,
                                localizedContent: marker?.localized || [],
                                keys: ["xCordinate", "yCordinate"],
                            });
                            const divStyleMarker = {
                                backgroundImage: `url(${markerImage ? markerImage.url.replace("t_mcms_larger", "t_mcms_small") : ""})`,
                                top: localizedMarkerCoordinates?.yCordinate + "%" || undefined,
                                left: localizedMarkerCoordinates?.xCordinate + "%" || undefined,
                            };
                            const sitePageUrl = mapMarkers?.find((mapMarker) => mapMarker.markerId === marker.markerId)?.sitePageUrl;
                            return (
                                <div key={marker.name}>
                                    {localizedMarkerDetails && (
                                        <div
                                            className={`map-view__pin map-view__pin-${localizedMarkerDetails.title || marker.name}
                                ${localizedMarkerDetails.imageOnTooltip ? "tooltip-img" : ""}`}
                                            style={divStyleMarker}
                                        >
                                            <div className="map-view__tooltip">
                                                <div className="media">
                                                    {localizedMarkerDetails.imageOnTooltip?.originalUrl && (
                                                        <div className="media-img">
                                                            <img loading="lazy" src={localizedMarkerDetails.imageOnTooltip.originalUrl} alt={alternativeText || image?.name || ""} />
                                                        </div>
                                                    )}
                                                    <div className="media-body">
                                                        <div className="map-view__title">{localizedMarkerDetails.title}</div>
                                                        <div className="map-view__desc">{localizedMarkerDetails.smallText}</div>
                                                        <SmartLink
                                                            href={localizedMarkerDetails.useExternalLink ? localizedMarkerDetails.externalUrl : sitePageUrl}
                                                            className="map-view__link"
                                                            target={localizedMarkerDetails.markerLinkNewTab ? "_blank" : "_self"}
                                                            // eslint-disable-next-line max-len
                                                            rel={localizedMarkerDetails.markerNoFollowLink ? "nofollow" : undefined}
                                                        >
                                                            {localizedMarkerDetails.linkName}
                                                        </SmartLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            );
        }
        return (
            <>
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                    style={classNames(fontColor?.includes("rgba") && fontColor)}
                />
                {showImage}
            </>
        );
    }
}

function mapStateToProps(state: State): ImageStoreProps {
    return {
        frontendPageEditState: state.frontendPageEditState,
    };
}

const ImageWithEditModal = withEditModal(ImageBase);

const imageConnect = connect<ImageStoreProps>(mapStateToProps)(CMSAware<ImagePropsBase>(ImageWithEditModal));

export const ImageWidget = wrapProps<ImagePropsBase>(imageConnect);

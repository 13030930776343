import * as React from "react";

import { FormSpec, getHelpId, localized, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { PageWidgetSpec, Widget } from "../../";
import { TypesearchContainerWidgetSpec, WidgetSpec, WidgetType } from "../../widget";
import { getClassName, getImageAndSpec, getLocalizedImageWidgetOptions, getMarkerAndAltText, getUrl } from "./imageWidget.utils";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ImageSpec } from "../../../media";
import { ImageWidget } from "./ImageWidget";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { getI18nLocaleObject } from "../../../i18n";
import { globalApiContext } from "../../../containers/CmsProvider";
import namespaceList from "../../../i18n/namespaceList";
import { pageSpec } from "../../../form-specs/models";

export interface Marker {
    markerId: number;
    name?: string;
    markerImage?: ImageSpec;
    localized?: LocalizedMarkerSpecOptions[];
    sitePageUrl?: string;
}

export interface LocalizedMarkerSpecOptions extends LocalizedContentBase {
    title: string;
    smallText: string;
    siteId: string;
    pageId: string;
    linkName: string;
    useExternalLink: string;
    useExternalUrl: string;
    xCordinate: string;
    yCordinate: string;
    markerLinkNewTab: boolean;
    externalUrl: string;
    imageOnTooltip?: ImageSpec;
    markerNoFollowLink: string;
}

export interface LocalizedImageWidgetOptions extends LocalizedContentBase {
    contentTitlePayoff: string;
    contentSubTitlePayoff: string;
    isSitePageUrl: boolean;
    siteId: string;
    pageId: string;
    useExtUrl: boolean; // TODO: db patch to useExternalUrl
    openNewTab: boolean;
    useExternalUrl: string; // TODO: db patch to externalUrl, this is supposed to store the external url if it is used (I think)
    noFollowLink: string;
    localizedImage?: ImageSpec;
}

export interface LocalizedFigcaption extends LocalizedContentBase {
    localizedFigcaption: string;
}

export interface ImageWidgetOptions extends LocalizedTitleOptions {
    styleIds: any[];
    imageSpec?: ImageSpec;
    imageUse: boolean;
    showImage: string;
    headerText: boolean;
    moreHeight: boolean;
    specifyHeight: specHeight;
    heightDesktop: string;
    heightTablet: string;
    heightMobile: string;
    imageUsePlacement: boolean;
    imageUseXPlacement: boolean;
    styleXPlacement: string;
    imageWidth: string;
    styleYPlacement: string;
    useMapMarkers: boolean;
    markers: Marker[];
    mobileSpecific: boolean;
    Usefigcaption: boolean;
    localized: LocalizedImageWidgetOptions[]; // TODO: db patch to localizedImageWidgetOptions
    localizedFig: LocalizedFigcaption[]; // TODO: db patch to localizedFigcaption
    styleXPlacementOnMobile: string;
    styleYPlacementOnMobile: string;
    changePositionOnMobile: boolean;
    removeFigureMargin: boolean;
    fullWidth: boolean;
    objectFit: objectFit;
    getLogoFromDC?: boolean;
}

type objectFit = "none" | "unset" | "cover" | "contain";
type specHeight = "" | "full-width" | "height";

export const TARGETS = ["image"];

const widgetOptionsForm: FormSpec<ImageWidgetOptions> = {
    id: "image-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetImage, "imageWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetImage, "imageWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetImage, "general"),
                    properties: [
                        [
                            {
                                type: "image",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                variable: "imageSpec",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "localizedImage",
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                        type: "image",
                                    },
                                ],
                            }),
                            {
                                variable: "useMapMarkers",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "useMapMarkers"),
                                type: "checkbox",
                            },
                            ...getWidgetTitleOptions<ImageWidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImage, "contentProperties"),
                    help: getHelpId("rKKwwH-content"),
                    properties: [
                        [
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "backgroundImage"),
                                variable: "imageUse",
                                groupName: "backgroundImageGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImage, "backgroundImageGroupTitle"),
                            },
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "localizedContentPayOff"),
                                variable: "localized",
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse,
                                tabContent: [
                                    {
                                        variable: "contentTitlePayoff",
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "title"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderShowTitleOnImage"),
                                    },
                                    {
                                        variable: "contentSubTitlePayoff",
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "subTitle"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderShowSubtitleOnImage"),
                                    },
                                ],
                            }),
                            /* Commented code will be removed after MCMS-9687 is done */
                            // {
                            //     type: "paragraph",
                            //     label: getI18nLocaleObject(namespaceList.widgetImage, "backgroundImageParagraph"),
                            //     groupName: "backgroundImageGroup",
                            //     visible: (options: ImageWidgetOptions) => options.imageUse,
                            // },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "imageUsePlacement"),
                                variable: "imageUsePlacement",
                                groupName: "backgroundImageGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImage, "backgroundImagePositionGroupTitle"),
                                visible: (options: ImageWidgetOptions) => options.imageUse,
                            },
                            {
                                type: "text",
                                variable: "styleYPlacement",
                                default: "center",
                                label: getI18nLocaleObject(namespaceList.admin, "styleYPlacement"),
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUsePlacement,
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "imageXPlacement"),
                                variable: "imageUseXPlacement",
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUsePlacement,
                            },
                            {
                                type: "text",
                                variable: "styleXPlacement",
                                default: "center",
                                label: getI18nLocaleObject(namespaceList.admin, "styleXPlacement"),
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUseXPlacement && options.imageUsePlacement,
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "changePositionOnMobile"),
                                variable: "changePositionOnMobile",
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUsePlacement,
                            },
                            {
                                type: "text",
                                variable: "styleXPlacementOnMobile",
                                default: "center",
                                label: getI18nLocaleObject(namespaceList.admin, "styleXPlacement"),
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUsePlacement && options.changePositionOnMobile,
                            },
                            {
                                type: "text",
                                variable: "styleYPlacementOnMobile",
                                default: "center",
                                label: getI18nLocaleObject(namespaceList.admin, "imageXPlacement"),
                                groupName: "backgroundImageGroup",
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.imageUsePlacement && options.changePositionOnMobile,
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "figcaptionContent"),
                                variable: "Usefigcaption",
                            },
                            localized({
                                variable: "localizedFig",
                                visible: (options: ImageWidgetOptions) => options.Usefigcaption,
                                tabContent: [
                                    {
                                        variable: "localizedFigcaption",
                                        label: getI18nLocaleObject(namespaceList.widgetImage, "figcaptionTitle"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderShowCaptionOnImage"),
                                    },
                                ],
                            }),
                            {
                                variable: "headerText",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "headerText"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImage, "pins"),
                    properties: [
                        [
                            {
                                variable: "markers",
                                type: "marker",
                                visible: (options: ImageWidgetOptions) => options.useMapMarkers,
                            },
                        ],
                    ],
                    visible: (options: ImageWidgetOptions) => options.useMapMarkers,
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "useExtUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "useExtUrl"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        variable: "useExternalUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                        type: "text",
                                        default: "",
                                        visible: (options: any) => options.useExtUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "site"),
                                        variable: "siteId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: autocompleteSiteSpec,
                                        visible: (options: any) => !options.useExtUrl,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "page"),
                                        variable: "pageId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "siteId",
                                        visible: (options: any) => !options.useExtUrl,
                                    },
                                    {
                                        variable: "openNewTab",
                                        label: getI18nLocaleObject(namespaceList.admin, "openNewTab"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        variable: "noFollowLink",
                                        label: getI18nLocaleObject(namespaceList.admin, "noFollowLink"),
                                        type: "checkbox",
                                        default: false,
                                        visible: (options: any) => options.useExtUrl,
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImageGallery, "dynamicImage"),
                    properties: [
                        [
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "getLogoFromDC"),
                                variable: "getLogoFromDC",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetImage, "styling"),
                    help: getHelpId("rKKwwH-styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<ImageWidgetOptions>(),
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "removeFigureMargin"),
                                variable: "removeFigureMargin",
                                groupName: "removeFigureMarginGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImage, "removeFigureMarginGroupTitle"),
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "fullWidth"),
                                variable: "fullWidth",
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "moreHeight"),
                                variable: "moreHeight",
                                visible: (options: ImageWidgetOptions) => options.imageUse,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightDesktop"),
                                variable: "heightDesktop",
                                placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderForMoreHeightOnDesktop"),
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.moreHeight,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightTablet"),
                                variable: "heightTablet",
                                placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderForMoreHeightOnTablet"),
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.moreHeight,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightMobile"),
                                variable: "heightMobile",
                                placeholder: getI18nLocaleObject(namespaceList.widgetImage, "placeholderForMoreHeightOnMobile"),
                                visible: (options: ImageWidgetOptions) => options.imageUse && options.moreHeight,
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "mobileSpecific"),
                                variable: "mobileSpecific",
                                visible: (options: ImageWidgetOptions) => !options.imageUse,
                            },
                            {
                                type: "text",
                                variable: "imageWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "imageWidth"),
                                visible: (options: ImageWidgetOptions) => !options.imageUse && options.mobileSpecific,
                                groupName: "imageWidthGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetImage, "imageWidthGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.widgetImage, "imageWidthGroupDescription"),
                            },
                            {
                                variable: "objectFit",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "objectFit"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "unset",
                                        label: "unset",
                                    },
                                    {
                                        value: "none",
                                        label: "none",
                                    },
                                    {
                                        value: "contain",
                                        label: "contain",
                                    },
                                    {
                                        value: "cover",
                                        label: "cover",
                                    },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

// eslint-disable-next-line max-lines-per-function
export function imageWidget(type: WidgetType) {
    const imageWidgetRender: WidgetSpec<ImageWidgetOptions> = {
        id: "image",
        type,
        widgetGroup: type === "page" || type === "resultsPanel" ? WidgetGroup.CONTENT : WidgetGroup.OTHER,
        name: getI18nLocaleObject(namespaceList.widgetImage, "imageWidget"),
        description: getI18nLocaleObject(namespaceList.widgetImage, "imageWidgetDescription"),
        optionsForm: widgetOptionsForm,
        defaultOptions: (): ImageWidgetOptions => ({
            styleIds: [],
            imageUse: false,
            showImage: "",
            headerText: false,
            moreHeight: false,
            specifyHeight: "",
            heightDesktop: "",
            heightTablet: "",
            heightMobile: "60",
            imageUsePlacement: false,
            imageUseXPlacement: false,
            styleXPlacement: "center",
            imageWidth: "auto",
            styleYPlacement: "center",
            useMapMarkers: false,
            markers: [],
            mobileSpecific: false,
            localized: [],
            localizedFig: [],
            Usefigcaption: false,
            changePositionOnMobile: false,
            styleXPlacementOnMobile: "",
            styleYPlacementOnMobile: "",
            removeFigureMargin: false,
            objectFit: "unset",
            fullWidth: false,
        }),
    };

    (imageWidgetRender as PageWidgetSpec<ImageWidgetOptions> | TypesearchContainerWidgetSpec<ImageWidgetOptions>).render = async (
        widget: Widget<ImageWidgetOptions>,
        context: CMSProvidedProperties
    ) => {
        const { site, currentLocale } = context;
        const apiContext = context?.mxtsApi ? context : globalApiContext();
        const styleClasses = getClassName(context, widget.options);
        const localizedImageWidgetOptions = getLocalizedImageWidgetOptions(widget.options, site, currentLocale);
        const { image, imageSpec } = await getImageAndSpec(apiContext, widget.options, localizedImageWidgetOptions);
        const url = await getUrl(context, site, currentLocale, localizedImageWidgetOptions);
        const { altText, markers } = await getMarkerAndAltText(apiContext, widget.options, site, currentLocale, imageSpec);
        return (
            <ImageWidget
                options={widget.options}
                context={context}
                className={styleClasses}
                url={url}
                image={image}
                imageSpec={imageSpec}
                mapMarkers={markers}
                alternativeText={altText}
                currentLocale={currentLocale}
                widgetOptionsForm={widgetOptionsForm}
                widgetId={widget._id}
                apiContext={apiContext}
            />
        );
    };
    return imageWidgetRender;
}

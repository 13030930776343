import { ApiContext, CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Image, ImageSpec, LocalizedImageSpecOptions, fallbackImage, findImageBySpec } from "../../../media";
import { ImageWidgetOptions, LocalizedImageWidgetOptions, LocalizedMarkerSpecOptions, Marker, TARGETS } from "./";
import { Site, WithId } from "@maxxton/cms-api";

import { CurrentLocale } from "../../../app.types";
import { WidgetOptions } from "../image-gallery";
import { allSites } from "../../../components/utils";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { pageLink } from "../../../routing";

export const getLocalizedImageWidgetOptions = (options: ImageWidgetOptions, site: Site, currentLocale: CurrentLocale): LocalizedImageWidgetOptions | null => {
    let localizedImageWidgetOptions: LocalizedImageWidgetOptions | null = null;
    if (options.localized) {
        localizedImageWidgetOptions = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
    }
    return localizedImageWidgetOptions;
};

export const getImageAndSpec = async (
    apiContext: ApiContext,
    options: ImageWidgetOptions | WidgetOptions,
    localizedImageWidgetOptions: LocalizedImageWidgetOptions | null
): Promise<{ image: Image | null; imageSpec: ImageSpec }> => {
    let imageSpec = localizedImageWidgetOptions?.localizedImage || options.imageSpec;
    const image: Image | null = await findImageBySpec(apiContext, imageSpec!);
    if (!imageSpec?.imageId) {
        imageSpec = fallbackImage;
        console.warn("No image configured");
    }
    return { image, imageSpec };
};

export const getMarkerAndAltText = async (apiContext: ApiContext, options: any, site: Site, currentLocale: any, imageSpec: any): Promise<{ altText: string; markers: any[] }> => {
    let markers: any[] = [];
    let altText = "";
    const altLocalizedImageSpecOptions: LocalizedImageSpecOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: imageSpec.options.localized });
    if (options.useMapMarkers && options.markers?.length) {
        const defaultLocalizedMarkerImageOptions: LocalizedImageSpecOptions = {
            locale: "",
        };
        const markersWithUrl = options.markers.map(async (marker: Marker) => {
            let imageMarker: Image | null;
            let markerImageUrl = "";
            const markerImage = marker.markerImage;
            if (markerImage) {
                imageMarker = await findImageBySpec(apiContext, markerImage);
                if (imageMarker) {
                    const localizedMarkerImageOptions: LocalizedImageSpecOptions =
                        getLocalizedContent({ site, currentLocale, localizedContent: markerImage.options.localized }) || defaultLocalizedMarkerImageOptions;
                    markerImageUrl = imageMarker.renderUrl(markerImage, localizedMarkerImageOptions);
                }
                if (imageSpec) {
                    altText = altLocalizedImageSpecOptions?.alt || imageSpec.caption || altLocalizedImageSpecOptions?.friendlyName || imageSpec.name || "";
                }
            }
            let sitePageUrl = "";
            const localizedMarkerLink: LocalizedMarkerSpecOptions | null = getLocalizedContent({
                site,
                currentLocale,
                localizedContent: marker?.localized || [],
                keys: ["pageId", "siteId"],
            });
            const sites = await apiContext.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
            if (localizedMarkerLink && sites?.length) {
                sitePageUrl = sitePageUrl.concat("//");
                const markerSite = sites.find((item) => item._id === localizedMarkerLink.siteId);
                if (markerSite) {
                    sitePageUrl = sitePageUrl.concat(markerSite.host);
                    const markerUrl = await pageLink({ site: markerSite, pageId: localizedMarkerLink.pageId, locale: currentLocale, context: apiContext });
                    sitePageUrl = sitePageUrl.concat(markerUrl ? markerUrl : "");
                }
            }
            return { markerId: marker.markerId, url: markerImageUrl, sitePageUrl };
        });
        markers = await Promise.all(markersWithUrl);
    } else {
        altText = altLocalizedImageSpecOptions?.alt || imageSpec.caption || altLocalizedImageSpecOptions?.friendlyName || imageSpec.name || "";
    }
    return { altText, markers };
};

export const getClassName = (context: CMSProvidedProperties, options: ImageWidgetOptions): string => findMultiSelectStyleClassNames(context.theme, TARGETS, options.styleIds);

export const getUrl = async (context: CMSProvidedProperties, site: Site & WithId, currentLocale: CurrentLocale, localizedImageWidgetOptions: LocalizedImageWidgetOptions | null): Promise<string> => {
    let url = "";
    let linkedSite;
    if (localizedImageWidgetOptions?.siteId) {
        if (site._id === localizedImageWidgetOptions.siteId) {
            linkedSite = site;
        } else if (allSites.length) {
            linkedSite = allSites.find((site: Site & WithId) => site._id === localizedImageWidgetOptions.siteId);
        } else {
            await context.cmsApi.siteApi.findById({ id: localizedImageWidgetOptions.siteId, projection: { sitemap: 0 } });
        }
    }

    if (linkedSite && localizedImageWidgetOptions) {
        url = url.concat("//");
        url = url.concat(context.site.enableMultiLanguage || localizedImageWidgetOptions.locale === currentLocale.locale ? linkedSite.host : context.site.host);
        const path: string | undefined = await pageLink({ site: linkedSite, pageId: localizedImageWidgetOptions.pageId, locale: currentLocale, context });
        url = url.concat(path || "");
    }
    return url;
};
